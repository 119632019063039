import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { AppPictureComponent } from "./app-picture/app-picture.component";
import { AssetItemComponent } from "./asset-item/asset-item.component";
import { ImportStatusBadgeComponent } from "./asset-item/import-status-badge/import-status-badge.component";
import { DocumentSummaryItemComponent } from "./document-summary-item/document-summary-item.component";
import { InputItemComponent } from "./input-item/input-item.component";
import { LegendItemComponent } from "./legend-item/legend-item.component";
import { LoaderComponent } from "./loader/loader.component";
import { PerimetersListItemComponent } from "./perimeters-list-item/perimeters-list-item.component";
import { ReferenceDataValueComponent } from "./reference-data-value/reference-data-value.component";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { EventService } from "../services";
import { AssetDetailSegmentComponent } from "./segments/asset-detail/asset-detail.component";
import { NextButtonComponent } from "./controls/next-button/next-button.component";
import { AssetLifecycleSegmentComponent } from "./segments/asset-lifecycle/asset-lifecycle.component";
import { TechnicalStateInputComponent } from "./notations/technical-state-input/technical-state-input.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { PieChartComponent } from "./controls/pie-chart/pie-chart.component";
import { InvestmentsListSegmentComponent } from "./segments/investments-list/investments-list.component";
import { InvestmentItemComponent } from "./investment-item/investment-item.component";
import { InvestmentItemBudgetComponent } from "./investment-item/investment-item-budget/investment-item-budget.component";
import { CurrencyPipeModule } from "../pipes/currency/currency.module";
import { RoadmapListItemComponent } from "./roadmap-list-item/roadmap-list-item.component";
import { PerimeterAddActionSheetComponent } from "./perimeter-add-action-sheet/perimeter-add-action-sheet.component";
import { DefaultValueModule } from "../pipes/default-value/default-value.module";
import { AssetAddActionSheetComponent } from "../pages/asset-detail/asset-add-action-sheet/asset-add-action-sheet.component";
import { AssetTypeSelectorComponent } from "./segments/asset-detail/asset-type-selector/asset-type-selector/asset-type-selector.component";
import { AssetExpertNotationComponent } from "./segments/asset-expert-notation/asset-expert-notation.component";
import { KpiNotationsComponent } from "./notations/kpi-notations/kpi-notations.component";
import { YesNoIdkNoteComponent } from "./notations/yes-no-idk-note/yes-no-idk-note.component";
import { OtherNotationsComponent } from "./notations/other-notations/other-notations.component";
import { CameraComponent } from "../pages/camera/camera/camera.component";
import { PictureDetailComponent } from "./picture-detail/picture-detail.component";
import { AssetInconsistentDataSuggestionComponent } from "./segments/asset-lifecycle/asset-inconsistent-data-suggestion/asset-inconsistent-data-suggestion.component";
import { DocumentItemComponent } from "./document-item/document-item.component";
import { StarsNoteComponent } from "./notations/stars-note/stars-note.component";
import { RangeNoteComponent } from "./notations/range-note/range-note.component";
import { OnOffNoteComponent } from "./notations/on-off-note/on-off-note.component";
import { PicturesSummaryItemComponent } from "./pictures-summary-item/pictures-summary-item.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { PicturesViewerComponent } from "../pages/pictures-viewer/pictures-viewer.component";
import { PinchZoomDirective } from "@services/directives/pinchzoom.directive";
import { BackButtonComponent } from "./controls/back-button/back-button.component";
import { PriceSheetsListComponent } from "../pages/price-sheets-list/price-sheets-list/price-sheets-list.component";
import { UsernamePipeModule } from "../pipes/username/username.module";
import { DocumentsAddPage } from "../pages/perimeter/documents-add/documents-add";
import { NoNetworkComponent } from "./no-network/no-network.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { MonoPerimeterListSegmentComponent } from "../pages/perimeter/monoperimeterlist/mono-perimeter-list-segment/mono-perimeter-list-segment.component";
import { SliceDetailComponent } from "../pages/investment-detail/investment-budget-slices/slice-detail/slice-detail.component";
import { InvestmentBudgetSlicesPage } from "../pages/investment-detail/investment-budget-slices/investment-budget-slices.page";
import { InvestmentTagsModalComponent } from "../pages/investment-detail/investment-tags/investment-tags-modal.component";
import { InvestmentImpactComponent } from "../pages/investment-detail/investment-impact/investment-impact.component";
import { InvestmentCategoriesComponent } from "../pages/investment-detail/investment-categories/investment-categories.component";
import { AssetParentSelectorComponent } from "../pages/asset-add/asset-parent-selector/asset-parent-selector.component";
import { InvestmentCategorySubcategorySelectorComponent } from "../pages/investment-detail/investment-category-subcategory-selector/investment-category-subcategory-selector.component";
import { InvestmentTagsComponent } from "../pages/investment-detail/investment-tags/investment-tags/investment-tags.component";
import { ActionListComponent } from "../pages/initiatives/components/action-list/action-list.component";
import { RisksListComponent } from "./risks-list/risks-list.component";
import { RiskItemComponent } from "./risks-list/risk-item/risk-item.component";
import { TasksFiltersComponent } from "./tasks-filters/tasks-filters.component";
import { RiskInformationsComponent } from "./risks-list/risk-informations/risk-informations.component";
import { CurrentAndFutureAssetsComponent } from "../pages/investment-detail/investment-assets/current-and-future-assets/current-and-future-assets.component";

const components = [
  PerimetersListItemComponent,
  LegendItemComponent,
  DocumentSummaryItemComponent,
  PicturesSummaryItemComponent,
  AppPictureComponent,
  ReferenceDataValueComponent,
  MainMenuComponent,
  AssetItemComponent,
  ImportStatusBadgeComponent,
  InputItemComponent,
  LoaderComponent,
  AssetDetailSegmentComponent,
  NextButtonComponent,
  AssetLifecycleSegmentComponent,
  TechnicalStateInputComponent,
  PieChartComponent,
  InvestmentsListSegmentComponent,
  InvestmentItemComponent,
  InvestmentItemBudgetComponent,
  RoadmapListItemComponent,
  PerimeterAddActionSheetComponent,
  AssetTypeSelectorComponent,
  AssetAddActionSheetComponent,
  AssetExpertNotationComponent,
  KpiNotationsComponent,
  YesNoIdkNoteComponent,
  OtherNotationsComponent,
  CameraComponent,
  PicturesViewerComponent,
  PictureDetailComponent,
  AssetInconsistentDataSuggestionComponent,
  DocumentItemComponent,
  StarsNoteComponent,
  RangeNoteComponent,
  OnOffNoteComponent,
  PinchZoomDirective,
  BackButtonComponent,
  PriceSheetsListComponent,
  DocumentsAddPage,
  NoNetworkComponent,
  CalendarComponent,
  MonoPerimeterListSegmentComponent,
  SliceDetailComponent,
  InvestmentBudgetSlicesPage,
  InvestmentTagsComponent,
  InvestmentTagsModalComponent,
  InvestmentImpactComponent,
  InvestmentCategoriesComponent,
  AssetParentSelectorComponent,
  InvestmentCategorySubcategorySelectorComponent,
  ActionListComponent,
  RisksListComponent,
  RiskItemComponent,
  TasksFiltersComponent,
  RiskInformationsComponent,
  CurrentAndFutureAssetsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    NgxSliderModule,
    CurrencyPipeModule,
    DefaultValueModule,
    ScrollingModule,
    UsernamePipeModule,
  ],
  declarations: components,
  exports: components,
  providers: [EventService],
})
export class ComponentsModule {}
