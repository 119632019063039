import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Perimeter } from "../../../structs/assets";
import { ModalController, NavController } from "@ionic/angular";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { PicturesViewerComponent } from "../../pictures-viewer/pictures-viewer.component";
import { Picture } from "@structs";
import { ScopeService } from "@services/scope.service";
import { PerimetersService } from "@services/perimeters.service";
import { Observable, of, Subscription } from "rxjs";
import { SynthesisService } from "@services/synthesis.service";
import * as moment from "moment";
import { PerimeterTypeSection } from "@services/roadmap-chapters.service";

export interface PerimeterElement {
  perimeter: Perimeter;
  perimeterTypeName: string;
  hasLevelChildren: boolean;
  budget?: number;
  assetCount?: number;
  assetCountOnChildren?: number;
  perimeterTypeSections?: PerimeterTypeSection[];
  criticalAssetsCount?: number;
  criticalAssetsCountOnChildren?: number;
}

@Component({
  selector: "app-monoperimeterlist",
  templateUrl: "./monoperimeterlist.component.html",
  styleUrls: ["./monoperimeterlist.component.scss"],
})
// Act as MonoPerimetersListPage in Ionic3
export class MonoPerimeterListComponent implements OnInit, OnDestroy {
  public mainPerimeter: Perimeter = null;
  public monoPerimeter: Perimeter = null;
  public monoPerimeterId: number = null;
  public searchText: string = "";
  public searchedPerimeters: PerimeterElement[] = [];

  private perimeterTypesNamesMap: Map<number, string> = new Map<number, string>();
  public pictureEmpty: Picture = new Picture(-1, null, null, null, null, null, null);

  public collapsedMultiPerimeterIds: number[] = [];
  @ViewChild("monoPerimeterList") private monoPerimeterList;
  private subscriptions: Subscription[] = [];
  public limitedList: number[] = null;
  private allowLevel2Perimeters: boolean = false;
  public synthesisReady$: Observable<boolean> = null;
  public newPerimeter: Perimeter;
  @Input() public modalMode: boolean = false;
  @Input() public selectedMonoPerimeterId: number | string = null;

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private scope: ScopeService,
    private router: Router,
    private perimetersService: PerimetersService,
    private route: ActivatedRoute,
    private synthesisService: SynthesisService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params["monoPerimeterId"]) {
          this.monoPerimeterId = parseInt(params["monoPerimeterId"]);
        }
      }),
      this.route.queryParams.subscribe(params => {
        const state = this.router.getCurrentNavigation()?.extras.state;
        if (state?.refresh) {
          this.getMainPerimeter();
          if (state.newPerimeter) {
            this.newPerimeter = state.newPerimeter;
          }
        }
      })
    );
  }

  public ionViewDidEnter(): void {
    // Refresh perimeter
    this.getMainPerimeter();
  }

  public monoPerimeterClicked(monoPerimeter: Perimeter) {
    if (this.modalMode) {
      this.modalCtrl.dismiss({ monoPerimeter });
    } else {
      this.showPerimeter(monoPerimeter);
    }
  }

  async showPerimeter(monoPerimeter: Perimeter): Promise<void> {
    await this.router.navigate([
      "perimeter",
      "mono-perimeters",
      monoPerimeter.id > 0 ? monoPerimeter.id : monoPerimeter.localId,
    ]);
  }

  public getPerimeterTypeName(monoPerimeter): string {
    let perimeterTypeName = this.perimeterTypesNamesMap.get(monoPerimeter.perimeterType) || "";
    return perimeterTypeName;
  }

  public addPerimeter(): void {
    let navExtras: NavigationExtras;
    if (this.monoPerimeter) {
      navExtras = {
        state: {
          perimeter: this.mainPerimeter,
          fixedLevelParent: this.monoPerimeter.id,
          parentName: this.monoPerimeter.name,
          parentLocalId: this.monoPerimeter.id === -1 ? this.monoPerimeter.localId : null,
          parentCreationYear: this.monoPerimeter.creationYear,
          parentDateConfidence: this.monoPerimeter.installationDateConfidence,
        },
      };
    }
    this.router.navigate(["mono-perimeter-add"], navExtras);
  }

  public searchPerimeters(): void {
    this.monoPerimeterList.searchPerimeters();
  }

  public showEnergyConsumptionPage() {
    this.router.navigate(["energy-consumption", this.mainPerimeter.id]);
  }

  public async pictureClicked(perimeter) {
    let options = {
      perimeter,
      pictureOnly: true,
      type: "perimeter",
    };
    let assetsModal = await this.modalCtrl.create({
      component: PicturesViewerComponent,
      componentProps: {
        options,
      },
    });
    await assetsModal.present();
  }

  // go to multi perimeter page
  public goToSitePage() {
    this.navCtrl.navigateBack(["perimeter"]);
  }

  public toggleMultiPerimeterChildren(perimeter: Perimeter): void {
    if (perimeter && !perimeter.level_parent) {
      const isCollapsed = this.collapsedMultiPerimeterIds.includes(perimeter.id);
      if (isCollapsed) {
        this.collapsedMultiPerimeterIds = this.collapsedMultiPerimeterIds.filter(id => id !== perimeter.id);
      } else {
        this.collapsedMultiPerimeterIds.push(perimeter.id);
      }
    }
  }

  public isCollapsed(perimeterId: number): boolean {
    return this.collapsedMultiPerimeterIds.includes(perimeterId);
  }

  private getMainPerimeter() {
    this.subscriptions.push(
      this.scope.getCurrentMultiPerimeter().subscribe(
        (perimeter: Perimeter) => {
          if (perimeter) {
            this.mainPerimeter = perimeter;
            if (!this.synthesisReady$) {
              this.synthesisReady$ = this.synthesisService.ensureAuditSynthesis(this.mainPerimeter, moment().year());
            }
            if (this.monoPerimeterId) {
              this.getMonoPerimeter();
            }
          } else {
            window.location.href = "/";
          }
        },
        () => {
          window.location.href = "/";
        }
      )
    );
  }
  private getMonoPerimeter() {
    this.monoPerimeter = this.mainPerimeter.sub_perimeters.find(
      subPerimeter => subPerimeter.id === this.monoPerimeterId
    );
    // We only show the children of this monoperimeter
    this.limitedList = this.mainPerimeter.sub_perimeters
      .filter(monoPerimeter => monoPerimeter.level_parent === this.monoPerimeterId)
      .map(perim => perim.id);
    // We check if we can add children to this mono-perimeter. If not, the button will
    // be hidden.
    this.subscriptions.push(
      this.perimetersService
        .areChildrenPerimetersAllowed(this.mainPerimeter.cluster, this.monoPerimeter, this.mainPerimeter)
        .subscribe(childrenAllowed => {
          this.allowLevel2Perimeters = childrenAllowed;
        })
    );
  }

  public deletePerimeter(monoPerimeter: Perimeter): void {
    const childrenPerimeters = this.mainPerimeter.sub_perimeters.filter(subPerimeter => {
      return subPerimeter.level_parent === monoPerimeter.id;
    });
    this.subscriptions.push(
      this.perimetersService.deletePerimeter(this.mainPerimeter, monoPerimeter, childrenPerimeters).subscribe(done => {
        this.getMainPerimeter();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe);
  }

  public backButtonClicked() {
    this.modalCtrl.dismiss();
  }

  public showAddButton() {
    return (
      (!this.monoPerimeterId || this.allowLevel2Perimeters) &&
      this.mainPerimeter &&
      !this.mainPerimeter.read_only &&
      !this.modalMode
    );
  }
}
