import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NavController, IonSelect } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import { AssetsService } from "../../../../../services/assets.service";
import { AssetEditService } from "../../../../../services/asset-edit.service";
import { ErrorsService } from "../../../../../services/errors.service";
import {
  Category,
  SubCategory,
  AssetType,
  getAvailableCategories,
  Perimeter,
  Asset,
  StepsIds,
  SuperCategory,
  makeCategory,
  AssetTypeLevel,
} from "../../../../../structs/assets";
import { formatSearchString } from "../../../../../structs/utils";
import { ScopeService } from "../../../../../services/scope.service";
import { Observable, combineLatest } from "rxjs/";
import { map } from "rxjs/operators";

class SearchResult {
  constructor(
    public assetType: AssetType,
    public subCategory: SubCategory,
    public category: Category,
    public matchingField?: number
  ) {}
}

enum matchingField {
  "assetType" = 1,
  "keyword" = 2,
  "subCategory" = 3,
  "category" = 4,
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-type-selector",
  templateUrl: "./asset-type-selector.component.html",
  styleUrls: ["./asset-type-selector.component.scss"],
})
export class AssetTypeSelectorComponent implements OnInit {
  private superCategories: SuperCategory[] = [];
  public categories: Category[] = [];
  public subCategories: SubCategory[] = [];
  public assetTypes: AssetType[] = [];
  public parentAssetType: AssetType = null;

  public selectedSuperCategoryId: number = null;
  public selectedCategoryId: number = null;
  public selectedSubCategoryId: number = null;
  public selectedAssetTypeId: number = null;
  public searchResults: SearchResult[] = [];
  public superCategories$: Observable<SuperCategory[]>;

  private cluster: number;
  private zone: number;
  private assetPerimeter: Perimeter;
  selectedSearchResult: number = null;
  @Input() public editMode: boolean = false;
  @Input() public searchMode: boolean = false;
  @Input() public parent: Asset = null;
  @Input() autoSelectAssetTypeId: number = 0;
  @Input() autoSelectSubCategoryId: number = 0;
  @Input() autoSelectCategoryId: number = 0;
  @Input() public searchText: string = "";
  @Input() public preventAssetTypesThatNeedParent: boolean = false;

  @Output() public assetTypeSelected = new EventEmitter<void>();
  @Output() public detailsChanged = new EventEmitter<any>();
  @Output() public searchModeToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("assetTypeSelect") assetTypeSelect: IonSelect;
  @ViewChild("subCategorySelect") subCategorySelect: IonSelect;

  constructor(
    private assetsApi: AssetsService,
    public assetEditService: AssetEditService,
    private errors: ErrorsService,
    private navCtrl: NavController,
    protected translate: TranslateService,
    private scope: ScopeService
  ) {}

  public ngOnInit(): void {
    this.superCategories$ = this.assetsApi.getSuperCategories().pipe(
      map(superCategories => {
        // The categories contained in the superCategory.children array don't have the correct
        // formatting. Some variables are written in snake case whereas they should be in camel case.
        return superCategories.map(superCategory => {
          const formattedCategories = superCategory.children.map(category => makeCategory(category));
          superCategory.children = formattedCategories;
          return superCategory;
        });
      })
    );
    this.scope.getSelectedPerimeter().subscribe(selectedPerimeter => {
      this.assetPerimeter = this.assetEditService.getPerimeter();
      this.cluster = this.assetPerimeter
        ? this.assetPerimeter.cluster
        : selectedPerimeter
        ? selectedPerimeter.cluster
        : null;
      const zoneObj = this.assetEditService.getZone();
      this.zone = zoneObj ? zoneObj.id : null;

      if (this.parent === null) {
        combineLatest([this.assetsApi.getCategories(), this.superCategories$]).subscribe(
          ([categories, superCategories]) => {
            this.categories = getAvailableCategories(categories, this.cluster, null);
            this.superCategories = superCategories;

            if (this.categories.length === 0) {
              this.translate.get("Configuration missing. Refresh the list of perimeters").subscribe(text => {
                this.errors.signalError(text);
              });
            } else {
              // we're either coming in for an existing asset or creating a new one,
              // optionally from the roadmap which will set the asset type and parents

              // priority to an existing asset
              if (this.assetEditService.getCategory()) {
                this.autoSelectCategoryId = this.assetEditService.getCategory().id;
              }

              if (this.assetEditService.getSubCategory()) {
                this.autoSelectSubCategoryId = this.assetEditService.getSubCategory().id;
              }

              if (this.assetEditService.getAssetType()) {
                this.autoSelectAssetTypeId = this.assetEditService.getAssetType().id;
              }

              if (this.autoSelectAssetTypeId) {
                this.autoSelectAssetType(categories, this.autoSelectAssetTypeId);
                if (!this.selectedAssetTypeId) {
                  // Add unavailable nomenclature
                  this.addNomenclature(
                    this.assetEditService.getCategory(),
                    this.assetEditService.getSubCategory(),
                    this.assetEditService.getAssetType()
                  );
                  this.autoSelectAssetType(this.categories, this.autoSelectAssetTypeId);
                }
                this.categoryChanged(false);
                this.subcategoryChanged(false);
                this.assettypeChanged(true);
                this.searchMode = false;
              } else if (this.autoSelectSubCategoryId) {
                this.autoSelectSubCategory(categories, this.autoSelectSubCategoryId);
                this.categoryChanged(false);
                this.subcategoryChanged(true);
                this.searchMode = false;
              } else if (this.autoSelectCategoryId) {
                this.autoSelectCategory(categories, this.autoSelectCategoryId);
                this.categoryChanged(true);
                this.searchMode = false;
                // open sub category list
                this.openSubCategoriesList();
              } else if (this.searchText) {
                this.onSearchChanged();
              }
            }
          },
          err => {
            this.errors.signalError(err);
          }
        );
      } else {
        // only one possible choice
        this.searchMode = false;
        this.categories = [];
        combineLatest([
          this.assetsApi.getAssetTypeCategory(this.parent.assetType),
          this.assetsApi.getAssetTypeSubCategory(this.parent.assetType),
          this.assetsApi.getChildAssetTypes(this.parent.assetType),
          this.superCategories$,
        ]).subscribe(([category, subCategory, allowedAssetTypes, superCategories]) => {
          this.superCategories = superCategories;
          subCategory.children = allowedAssetTypes;
          category.children = [subCategory];
          this.categories.push(category);
          if (allowedAssetTypes.length === 1) {
            this.autoSelectAssetType(this.categories, allowedAssetTypes[0].id);
            this.categoryChanged(false);
            this.subcategoryChanged(false);
            this.assettypeChanged(true);
          } else {
            this.autoSelectSubCategory(this.categories, subCategory.id);
            this.categoryChanged(false);
            this.subcategoryChanged(false);
            if (!this.assetEditService.getAssetType()) {
              // open asset types list
              this.openAssetTypesList();
            }
          }
        });
      }
    });
  }

  public dismiss(): void {
    if (this.searchMode && this.searchResults.length === 1) {
      this.selectSearchResult(this.searchResults[0]);
    }
    this.navCtrl.pop();
  }

  public superCategoryChanged(clearDown, superCategories) {
    if (!this.selectedSuperCategoryId) {
      this.selectedCategoryId = null;
    } else {
      const selectedSuperCategory = superCategories.find(superCat => superCat.id === this.selectedSuperCategoryId);
      if (selectedSuperCategory && clearDown) {
        this.selectedCategoryId === null;
        this.categories = getAvailableCategories(selectedSuperCategory.children, this.cluster, this.zone);

        if (this.categories.length === 1) {
          this.selectedCategoryId = this.categories[0].id;
        }
        this.categoryChanged(true);
      }
    }
  }

  public categoryChanged(clearDown: boolean): void {
    let selectedCategory: Category = null;
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].id === this.selectedCategoryId) {
        selectedCategory = this.categories[i];
        if (clearDown) {
          this.subCategories = this.categories[i].children;
          this.assetTypes = [];
        }
        break;
      }
    }

    if (clearDown) {
      this.selectedSubCategoryId = null;
      this.selectedAssetTypeId = null;
    }
    this.assetEditService.categoryChanged(selectedCategory);
    if (selectedCategory) {
      const selectedSuperCategory = this.superCategories.find(
        superCategory => superCategory.id === selectedCategory.parent
      );
      this.categories = getAvailableCategories(selectedSuperCategory.children, this.cluster, this.zone);
    }
  }

  public subcategoryChanged(clearDown: boolean): void {
    let selectedSubCategory: SubCategory = null;
    for (let i = 0; i < this.subCategories.length; i++) {
      if (this.subCategories[i].id === this.selectedSubCategoryId) {
        selectedSubCategory = this.subCategories[i];
        if (clearDown) {
          this.assetTypes = this.subCategories[i].children.filter(assetType => this.isAssetTypeAllowed(assetType));
        }
        break;
      }
    }

    if (clearDown) {
      this.selectedAssetTypeId = null;
      // Auto-select if only one choice possible
      if (this.assetTypes.length === 1) {
        this.selectedAssetTypeId = this.assetTypes[0].id;
        this.assettypeChanged();
      } else {
        this.openAssetTypesList();
      }
    }
    this.assetEditService.subcategoryChanged(selectedSubCategory);
  }

  public assettypeChanged(init?: boolean): void {
    let selectedAssetType: AssetType = null;
    for (let i = 0; i < this.assetTypes.length; i++) {
      if (this.assetTypes[i].id === this.selectedAssetTypeId) {
        selectedAssetType = this.assetTypes[i];
        break;
      }
    }

    this.assetEditService.assettypeChanged(selectedAssetType);
    if (init) {
      this.detailsChanged.emit({
        nextStep: StepsIds.DETAIL,
        goNext: false,
        nextLabel: "next",
        stepValid: !!selectedAssetType,
      });
    } else {
      this.detailsChanged.emit({
        nextStep: StepsIds.DETAIL,
        goNext: true,
        nextLabel: "next",
        stepValid: !!selectedAssetType,
      });
      this.assetTypeSelected.emit();
    }
  }

  public selectSearchResult(searchResult: SearchResult, i?: number) {
    // click on a search result item;
    if (!this.isAssetTypeAllowed(searchResult.assetType)) {
      return;
    }
    this.assetEditService.categoryChanged(searchResult.category);
    this.assetEditService.subcategoryChanged(searchResult.subCategory);
    this.assetEditService.assettypeChanged(searchResult.assetType);
    if (i !== null) {
      this.selectedSearchResult = i;
    }
    setTimeout(() => {
      // A timeout before closing, just so the user can see his choice highlighted in green
      this.detailsChanged.emit({
        nextStep: StepsIds.DETAIL,
        goNext: true,
        nextLabel: "next",
      });
      this.assetTypeSelected.emit();
    }, 500);
  }

  public onSearchCancelled() {}

  public onSearchChanged(event?) {
    this.searchMode = true;
    let formattedSearchText: string;
    if (event) {
      this.searchText = event.target.value;
    }
    if (this.searchText && this.searchText !== "") {
      // remove accents and make lower case, for better results
      formattedSearchText = formatSearchString(this.searchText);
    } else {
      formattedSearchText = "";
    }
    let unsortedResults = this.categories.reduce((matching: SearchResult[], elt: Category) => {
      // look for matching asset types in children
      let subCategoryResults = this.getSubCategoriesSearchResults(elt, formattedSearchText);
      if (subCategoryResults.length > 0) {
        matching = matching.concat(subCategoryResults);
      }
      return matching;
    }, []);
    this.searchResults = this.sortSearchResults(unsortedResults, formattedSearchText);
  }

  private getSubCategoriesSearchResults(category: Category, searchText: string): SearchResult[] {
    return category.children.reduce((matching: SearchResult[], elt: SubCategory) => {
      // look for matching asset types in children
      let assetTypesResults = this.getAssetTypesSearchResults(category, elt, searchText);
      if (assetTypesResults.length > 0) {
        matching = matching.concat(assetTypesResults);
      }
      return matching;
    }, []);
  }

  private doKeywordsMatch(assetType: AssetType, searchText: string): boolean {
    let matchingKeyword = assetType.keywords.find(keyword => formatSearchString(keyword.name).includes(searchText));
    return !!matchingKeyword;
  }

  private getAssetTypesSearchResults(category: Category, subCategory: SubCategory, searchText: string): SearchResult[] {
    return subCategory.children.reduce((matching: SearchResult[], assetType: AssetType) => {
      if (searchText === "") {
        matching.push(new SearchResult(assetType, subCategory, category));
      } else if (formatSearchString(assetType.name).includes(searchText)) {
        matching.push(new SearchResult(assetType, subCategory, category, matchingField.assetType));
      } else if (formatSearchString(subCategory.name).includes(searchText)) {
        matching.push(new SearchResult(assetType, subCategory, category, matchingField.subCategory));
      } else if (formatSearchString(category.name).includes(searchText)) {
        matching.push(new SearchResult(assetType, subCategory, category, matchingField.category));
      } else if (this.doKeywordsMatch(assetType, searchText)) {
        matching.push(new SearchResult(assetType, subCategory, category, matchingField.keyword));
      }
      return matching;
    }, []);
  }

  private autoSelectAssetType(categories: Category[], assetTypeId: number): void {
    for (let i = 0; i < categories.length; i++) {
      let category: Category = categories[i];
      for (let j = 0; j < category.children.length; j++) {
        let subCategory: SubCategory = category.children[j];
        for (let k = 0; k < subCategory.children.length; k++) {
          let assetType: AssetType = subCategory.children[k];
          if (assetType.id === assetTypeId) {
            this.selectedCategoryId = category.id;
            this.subCategories = category.children;
            this.selectedSubCategoryId = subCategory.id;
            this.assetTypes = subCategory.children.filter(assetType =>
              this.assetsApi.isAssetTypeAllowedForPerimeter(assetType, this.assetPerimeter.perimeterType, this.cluster)
            );
            this.selectedAssetTypeId = assetType.id;
            this.selectedSuperCategoryId = category.parent;
            return; // Done
          }
        }
      }
    }
  }

  private autoSelectSubCategory(categories: Category[], subCategoryId: number): void {
    for (let i = 0; i < categories.length; i++) {
      let category: Category = categories[i];
      for (let j = 0; j < category.children.length; j++) {
        let subCategory: SubCategory = category.children[j];
        if (subCategory.id === subCategoryId) {
          this.selectedCategoryId = category.id;
          this.subCategories = category.children;
          this.selectedSubCategoryId = subCategory.id;
          this.selectedSuperCategoryId = category.parent;
          this.assetTypes = subCategory.children.filter(assetType => this.isAssetTypeAllowed(assetType));
          return; // Done
        }
      }
    }
  }

  private autoSelectCategory(categories: Category[], categoryId: number): void {
    for (let i = 0; i < categories.length; i++) {
      let category: Category = categories[i];
      if (category.id === categoryId) {
        this.selectedCategoryId = category.id;
        this.subCategories = category.children;
        this.selectedSuperCategoryId = category.parent;
        return; // Done
      }
    }
  }

  private openAssetTypesList() {
    let ev = new UIEvent("UIEvent", {});
    setTimeout(() => {
      // Has to be in a settimeout() or else the html components aren't ready and
      // the command doesn't work
      this.assetTypeSelect.open(ev);
    }, 0);
  }

  private openSubCategoriesList() {
    let ev = new UIEvent("UIEvent", {});
    setTimeout(() => {
      // Has to be in a settimeout() or else the html components aren't ready and
      // the command doesn't work
      this.subCategorySelect.open(ev);
    }, 0);
  }

  private needsParent(assetType: AssetType) {
    return (
      assetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM || assetType.level === AssetTypeLevel.LEVEL_COMPONENT
    );
  }

  public isAssetTypeAllowed(assetType: AssetType) {
    return (
      this.assetsApi.isAssetTypeAllowedForPerimeter(assetType, this.assetPerimeter.perimeterType, this.cluster) &&
      !(this.preventAssetTypesThatNeedParent && this.needsParent(assetType))
    );
  }

  /**
   * Some existing assets have a nomenclature that is unavailable for some reason (cluster, ...)
   * We still need to let them load their nomenclature, so we add it to the categories that
   * we already have.
   */
  private addNomenclature(category: Category, subCategory: SubCategory, assetType: AssetType) {
    // The technical categories that we get from the asset might not have the correct structure
    // and might not be slotted so we have to do some formatting
    if (typeof subCategory.children === "number") {
      subCategory.children = [];
    }
    if (typeof category.children === "number") {
      category.children = [];
    }
    if (!subCategory.children.find(aType => aType.id === assetType.id)) {
      subCategory.children.push(assetType);
    }
    if (!category.children.find(subCat => subCat.id === subCategory.id)) {
      category.children.push(subCategory);
    }

    const categoryIndex = this.categories.findIndex(cat => cat.id === category.id);
    if (categoryIndex !== -1) {
      // The category is already in the list. Checking for the subcategory.
      const subCategoryIndex = this.categories[categoryIndex].children.findIndex(
        subCat => subCat.id === subCategory.id
      );
      if (subCategoryIndex !== -1) {
        // Subcategory is there too, we add the assetType among its children.
        this.categories[categoryIndex].children[subCategoryIndex].children.push(assetType);
      } else {
        // Subcategory isn't there so we add it.
        this.categories[categoryIndex].children.push(subCategory);
      }
    } else {
      // The category isn't in the list so we add it.
      this.categories.push(category);
    }
  }

  private sortSearchResults(results: SearchResult[], searchText: string): SearchResult[] {
    return results.sort((a, b) => {
      // sorting by matching field (priority to assetType and assetType.keywords)
      if (a.matchingField === b.matchingField) {
        if (a.matchingField === matchingField.assetType) {
          // if both assetTypes have a match, priority to the one starting with the searchtext
          if (
            formatSearchString(a.assetType.name).indexOf(searchText) === 0 &&
            formatSearchString(b.assetType.name).indexOf(searchText) > 0
          ) {
            return -1;
          }
          if (
            formatSearchString(a.assetType.name).indexOf(searchText) > 0 &&
            formatSearchString(b.assetType.name).indexOf(searchText) === 0
          ) {
            return 1;
          }
          // If both assetTypes start with the searchText, sort by alphabetical order
          if (formatSearchString(a.assetType.name) < formatSearchString(b.assetType.name)) {
            return -1;
          }
          if (formatSearchString(a.assetType.name) > formatSearchString(b.assetType.name)) {
            return 1;
          }
          return 0;
        } else {
          if (formatSearchString(a.assetType.name) < formatSearchString(b.assetType.name)) {
            return -1;
          }
          if (formatSearchString(a.assetType.name) > formatSearchString(b.assetType.name)) {
            return 1;
          }
          return 0;
        }
      } else {
        if (a.matchingField < b.matchingField) {
          return -1;
        }
        if (a.matchingField > b.matchingField) {
          return 1;
        }
        return 0;
      }
    });
  }
}
