import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController, NavController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { SearchService } from "@services";
import { AuthService } from "@services/auth.service";
import { ErrorsService } from "@services/errors.service";
import { OfflineService } from "@services/offline.service";
import { RiskService } from "@services/risks.service";
import { ScopeService } from "@services/scope.service";
import { TasksService } from "@services/tasks.service";
import { AppUser, Asset, Investment, Perimeter, Task, TaskStateDisplay } from "@structs";
import { Risk } from "@structs/risk";
import * as moment from "moment";
import { combineLatest, Observable, Observer, of, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-task-detail",
  templateUrl: "./task-detail.component.html",
  styleUrls: ["./task-detail.component.scss"],
})
export class TaskDetailComponent implements OnInit, OnDestroy {
  public task: Partial<Task> = null;
  public relatedAsset: Asset;
  public relatedInvestment: Investment;
  public currentMultiPerimeter: Perimeter;
  public users: AppUser[];
  public taskStates: TaskStateDisplay[];
  public readOnly: boolean = true;
  public title: string = "";
  public minDate: number = moment().add(-50, "year").year();
  public maxDate: number = moment().add(50, "year").year();
  public perimeters: Perimeter[];
  public risk: Risk;

  private asset: Asset;
  private subscriptions: Subscription[] = [];
  private callback: () => void = null;
  private hasPreviousPage: boolean;
  private taskId: number = null;
  private previousValue = null;
  private hideRiskLink: boolean = false;
  @Input() private modalMode: boolean = false;
  @Output() nextLabelChanged = new EventEmitter<any>();

  constructor(
    private authApi: AuthService,
    private errors: ErrorsService,
    private navCtrl: NavController,
    private offlineService: OfflineService,
    private params: NavParams,
    private scope: ScopeService,
    private tasksApi: TasksService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private searchService: SearchService,
    private riskService: RiskService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams
        .pipe(
          tap(() => {
            this.hasPreviousPage = !!this.router.getCurrentNavigation()?.previousNavigation;
            const state = this.router.getCurrentNavigation()?.extras?.state;
            if (state) {
              this.task = state.task;
              this.risk = state.risk;
              if (this.task && this.isNewTask()) {
                // Put a default label/description for the new task if provided
                this.task.label = state.label;
                this.task.description = state.description;
              }
              // If we come from the risk page, we don't need the risk link. We can just click back
              this.hideRiskLink = state.risk;
            }
          }),
          switchMap(() => combineLatest([this.scope.getCurrentMultiPerimeter(), this.route.params])),
          switchMap(([site, params]) => {
            this.currentMultiPerimeter = site;
            this.perimeters = this.currentMultiPerimeter.sub_perimeters;
            this.taskId = params["taskId"] ? parseInt(params["taskId"]) : null;
            if (this.taskId) {
              return this.loadTaskFromId(this.taskId);
            }
            return of(null);
          }),
          switchMap(task => {
            if (task) {
              this.task = task;
            }
            return combineLatest([this.offlineService.getConfig("users"), this.offlineService.getConfig("taskStates")]);
          })
        )
        .subscribe(
          ([users, taskStates]) => {
            this.taskStates = taskStates;
            this.users = users.filter(user =>
              user.perimeter_permissions.some(p => p.perimeter === this.currentMultiPerimeter.id)
            );

            this.loadRisk();
            // Load related asset
            if (this.task?.asset) {
              this.subscriptions.push(
                this.offlineService.getAsset(this.task.asset).subscribe(
                  asset => {
                    this.relatedAsset = asset;
                  },
                  err => this.errors.signalError(err)
                )
              );
              // Load related investment
            } else if (this.task?.investment) {
              this.subscriptions.push(
                this.offlineService.getInvestment(this.task.investment).subscribe(
                  investment => {
                    if (investment) {
                      this.relatedInvestment = investment;
                      if (investment.assetId) {
                        this.loadAssetFromInvestment(investment);
                      }
                    } else {
                      console.warn("couldn't load the task's investment");
                    }
                  },
                  err => this.errors.signalError(err)
                )
              );
            }
            // By default, assign a new task to the current user
            if (this.isNewTask()) {
              this.authApi.getCurrentUser().then(user => {
                if (user) {
                  this.task.assigned = user.get_user_id;
                }
              });
            }
            this.nextLabelChanged.emit(this.getSaveButtonLabel());
          },
          err => {
            this.errors.signalError(err);
          }
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public getPageTitle(): string {
    let title = "";
    if (this.isNewTask()) {
      title = this.translate.instant("New risk task");
    } else {
      title = this.translate.instant("Risk task");
    }
    return title;
  }

  /**
   * Transfers the user to the attached asset details page
   */
  public loadAssetDetails() {
    this.router.navigate(["perimeters", this.currentMultiPerimeter.id, "asset-detail", this.relatedAsset.id]);
  }

  /**
   * Transfers the user to the attached investment details page
   */
  public loadInvestmentDetails() {
    this.router.navigate(["perimeters", this.currentMultiPerimeter.id, "investment-detail", this.relatedInvestment.id]);
  }

  /**
   * Creates or updates the task.
   *
   * @param eventCompletedObserver Observer for the next button to signal our operation is finished.
   */
  public save(eventCompletedObserver: Observer<boolean>): void {
    if (this.modalMode) {
      this.close({ task: this.task });
    } else {
      const operation = this.isNewTask()
        ? this.tasksApi.createTask(this.task)
        : this.tasksApi.updateTask(<Task>this.task);
      operation.subscribe(() => {
        if (this.callback) {
          this.callback();
        }
        eventCompletedObserver.next(true);
        this.navCtrl.pop();
      });
    }
  }

  /**
   * Recovers the asset from the asset id stored in investment
   *
   * @param investment
   */
  private loadAssetFromInvestment(investment: Investment): void {
    if (investment !== null) {
      this.subscriptions.push(
        // Subcription to recover the task states
        this.offlineService.getAsset(investment.assetId, true).subscribe(
          (asset: Asset) => {
            this.asset = asset;
          },
          err => {
            this.errors.signalError(err);
          }
        )
      );
    }
  }

  /**
   * Checks if the model is valid
   */
  public canSaveTask(): boolean {
    if (!this.task) {
      return false;
    }
    const { label, assigned, deadline, state } = this.task;
    return label != null && assigned != null && deadline != null && state != null;
  }

  public isNewTask(): boolean {
    return !this.task || !(this.task.id || this.task.local_id);
  }

  public getSaveButtonLabel(): string {
    if (this.risk && this.isNewTask()) {
      return this.translate.instant("Add risk task");
    }
    return "save";
  }

  public close(data?: any) {
    if (this.modalMode) {
      this.modalCtrl.dismiss(data);
    } else {
      if (this.hasPreviousPage) {
        this.navCtrl.pop();
      } else {
        this.navCtrl.navigateBack(["perimeter"], { animated: false });
      }
    }
  }

  private loadTaskFromId(taskId: number): Observable<Task> {
    return this.searchService.generateTasksFilters().pipe(
      switchMap(taskFilters => this.searchService.searchTasks(true)),
      map(tasks => tasks.find(task => task.id === taskId))
    );
  }

  public setPreviousValue(event: any) {
    if (this.isNewTask()) {
      return;
    }
    this.previousValue = event.target.value;
  }

  public saveFieldIfChanged(fieldName: string) {
    if (this.isNewTask()) {
      return;
    }
    if (this.task[fieldName] !== this.previousValue) {
      this.saveChanges();
      this.previousValue = null;
    }
  }

  public saveChanges() {
    if (this.isNewTask()) {
      return;
    }
    this.subscriptions.push(
      this.tasksApi.updateTask(<Task>this.task).subscribe(taskUpdated => {
        console.log("TaskUpdated", taskUpdated);
      })
    );
  }

  /**
   * Go to the risks list on the mono-perimeter sheet and auto-scroll to the specified risk.
   * @param risk
   */
  public openRisk() {
    this.router.navigate(["perimeter/mono-perimeters/" + this.task.perimeter], {
      state: {
        riskSubCategoryId: this.risk.sub_category.id,
      },
    });
  }

  private loadRisk() {
    if (this.task) {
      this.subscriptions.push(
        this.riskService.getPerimeterRisks(this.task.perimeter).subscribe(risks => {
          const risk = risks.find(r => r.task_ids.includes(this.task.id));
          if (!this.risk) {
            this.risk = risk;
          }
        })
      );
    }
  }

  public showRiskLink(): boolean {
    return !this.hideRiskLink && !this.isNewTask() && !!this.risk;
  }
}
