import { Injectable } from "@angular/core";
import { BackendService } from "./backend.service";
import { makePicture, Picture, Task } from "@structs";
import { from, Observable, ReplaySubject } from "rxjs";
import { Risk, RiskCategory, RiskCriticality, RiskPicture } from "@structs/risk";
import { AuthService } from "./auth.service";
import { map, switchMap, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Environment } from "../app.environment";
import { FileTransfer, FileUploadOptions } from "@awesome-cordova-plugins/file-transfer/ngx";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class RiskService {
  public perimeterRisks$: ReplaySubject<Risk[]> = new ReplaySubject<Risk[]>(1);
  // Triggered when the mono-perimeter's risk list needs to be reloaded
  public riskListNeedsRefresh$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public siteRisks$: ReplaySubject<Risk[]> = new ReplaySubject<Risk[]>(1);
  // Risk waiting to be attached to an investment that is being created.
  private pendingRiskId: number = null;
  private siteId: number = null;

  constructor(
    private backend: BackendService,
    private authService: AuthService,
    private httpClient: HttpClient,
    private transfer: FileTransfer,
    private translate: TranslateService
  ) {}

  /**
   * Get the risk categories
   * @returns
   */
  public getAllRisks(): Observable<RiskCategory[]> {
    const url = "/risks/api/categories";
    return this.backend.get(url);
  }

  public getPerimeterRisks(perimeterId: number, updateCount = true): Observable<Risk[]> {
    const url = "/structures/api/perimeters/" + perimeterId + "/risks";
    return this.backend.get(url).pipe(
      tap(perimetersRisks => {
        if (updateCount) {
          this.perimeterRisks$.next(perimetersRisks);
        }
      })
    );
  }

  public getSiteRisks(siteId: number) {
    const url = "/structures/api/sites/" + siteId + "/risks-list";
    return this.backend.get(url);
  }

  public refreshSiteRisks(siteId: number) {
    this.getSiteRisks(siteId).subscribe(siteRisks => {
      this.siteRisks$.next(siteRisks);
    });
  }

  public createPerimeterRisk(riskSubCategoryId: number, perimeterId: number) {
    const data = {
      sub_category_id: riskSubCategoryId,
    };
    const url = "/structures/api/perimeters/" + perimeterId + "/risks/create";
    return this.backend.post(url, data).pipe(
      tap(() => {
        if (this.siteId) {
          this.refreshSiteRisks(this.siteId);
        }
      })
    );
  }

  public updateRisk(riskId: number, data) {
    const url = "/risks/api/risk/" + riskId + "/update";
    return this.backend.patch(url, data);
  }

  public deleteRisk(risk: Risk) {
    const url = "/risks/api/risk/" + risk.id + "/delete";
    return this.backend.postDelete(url, {}).pipe(
      tap(() => {
        if (this.siteId) {
          this.refreshSiteRisks(this.siteId);
        }
      })
    );
  }

  public addBrowserPicture(riskId: number, image: Picture) {
    const url = "/risks/api/risk/" + riskId + "/upload-images";
    return from(this.authService.getAuthorizationString()).pipe(
      switchMap(token => {
        const formData = new FormData();
        formData.append("images", image.browserFile, image.browserFile.name);
        formData.append("local_id", image.localId);

        return this.httpClient.post<Picture>(`${Environment.getBackendHost()}${url}`, formData, {
          headers: {
            Authorization: token,
          },
        });
      })
    );
  }

  public addDevicePicture(riskId: number, image: Picture): Observable<any> {
    const { localPath, localId }: Picture = image;
    const fileName: string = localPath.substring(localPath.lastIndexOf("/") + 1);
    const url = "/risks/api/risk/" + riskId + "/upload-images";

    return from(this.authService.getAuthorizationString()).pipe(
      switchMap(token => {
        const fileTransfer = this.transfer.create();
        const options: FileUploadOptions = {
          fileKey: "images",
          fileName,
          params: {
            local_id: localId,
          },
          httpMethod: "POST",
          headers: {
            Authorization: token,
          },
          chunkedMode: false,
        };

        return from(fileTransfer.upload(localPath, `${Environment.getBackendHost()}${url}`, options)).pipe(
          map(result => {
            return makePicture(JSON.parse(result.response));
          })
        );
      })
    );
  }

  public deletePicture(riskId: number, pictureId: number): Observable<any> {
    const url = "/risks/api/risk/" + riskId + "/images/" + pictureId;
    return this.backend.postDelete(url);
  }

  public attachInvestment(riskId: number, investmentId: number): Observable<any> {
    const url = "/risks/api/risk/" + riskId + "/investments/create";
    return this.backend.post(url, { investment_id: investmentId }).pipe(
      tap(() => {
        if (this.siteId) {
          this.refreshSiteRisks(this.siteId);
        }
      })
    );
  }

  public getRiskColor(criticality: number): string {
    let color: string;
    switch (criticality) {
      case 1:
        color = "risk-warning";
        break;
      case 2:
        color = "risk-important";
        break;
      case 3:
        color = "risk-major";
        break;
      case null:
        color = "";
        break;
      default:
        color = "risk-warning";
        break;
    }
    return color;
  }

  public detachInvestment(riskId: number, investmentId: number): Observable<any> {
    const url = "/risks/api/risk/" + riskId + "/investments/" + investmentId + "/";
    return this.backend.postDelete(url).pipe(
      tap(() => {
        if (this.siteId) {
          this.refreshSiteRisks(this.siteId);
        }
      })
    );
  }

  public createTask(riskId: number, task: Task): Observable<any> {
    const data = {
      label: task.label,
      description: task.description,
      assigned: task.assigned,
    };
    const url = "/risks/api/risk/" + riskId + "/tasks/create";
    return this.backend.post(url, task);
  }

  public formatForPictureComponent(riskImage: RiskPicture) {
    return new Picture(riskImage.id, riskImage.image, riskImage.thumbnail, null, null, null, null);
  }

  public getRiskCriticalityLabel(risk: Risk) {
    let criticalityLabel = "";
    switch (risk.criticality) {
      case RiskCriticality.MODERATE:
        criticalityLabel = this.translate.instant("moderate risk");
        break;
      case RiskCriticality.IMPORTANT:
        criticalityLabel = this.translate.instant("important risk");
        break;
      case RiskCriticality.MAJOR:
        criticalityLabel = this.translate.instant("major risk");
        break;
      default:
        criticalityLabel = this.translate.instant("Unknown");
        break;
    }
    return criticalityLabel;
  }

  public setSiteId(siteId: number) {
    this.siteId = siteId;
  }

  public setPendingRiskId(riskId: number) {
    this.pendingRiskId = riskId;
  }

  public getPendingRiskId() {
    return this.pendingRiskId;
  }
}
