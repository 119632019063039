import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IonItemSliding } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Investment, Asset, ASSET_STEP_ARCHIVED, INVESTMENT_TYPE_NO_ASSET } from "@structs";

@Component({
  selector: "app-current-and-future-assets",
  templateUrl: "./current-and-future-assets.component.html",
  styleUrls: ["./current-and-future-assets.component.scss"],
})
export class CurrentAndFutureAssetsComponent implements OnInit {
  @Input() investment: Investment = null;
  @Input() currentAsset: Asset = null;
  @Input() nextAsset: Asset = null;
  @Input() preventSwiping: boolean = false;

  @Output() currentAssetClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() detachInvestmentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextAssetClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteNextAssetEvent: EventEmitter<any> = new EventEmitter<any>();

  public archivedStep = ASSET_STEP_ARCHIVED;
  public nextAssetDesignation: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.showCurrentAsset()) {
      this.nextAssetDesignation = this.translate.instant("Replacement equipment");
    } else {
      this.nextAssetDesignation = this.translate.instant("Future equipment");
    }
  }

  currentAssetClicked() {
    this.currentAssetClickedEvent.emit();
  }

  detachInvestment(item: IonItemSliding) {
    item.close();
    this.detachInvestmentEvent.emit();
  }

  nextAssetClicked() {
    this.nextAssetClickedEvent.emit();
  }

  deleteNextAsset(item: IonItemSliding) {
    item.close();
    this.deleteNextAssetEvent.emit();
  }

  public showCurrentAsset() {
    const investmentType = this.investment?.investmentType;
    return investmentType && investmentType.instructionCurrentAsset !== INVESTMENT_TYPE_NO_ASSET;
  }

  public showNextAsset() {
    const investmentType = this.investment?.investmentType;
    return investmentType && investmentType.instructionNextAsset !== INVESTMENT_TYPE_NO_ASSET;
  }
}
