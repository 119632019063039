import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActionSheetController, AlertController, IonItemSliding, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BrowserService } from "@services/browser.service";
import { PicturesService } from "@services/pictures.service";
import { RiskService } from "@services/risks.service";
import { getLocalId, Perimeter, Picture } from "@structs";
import { Risk, RiskPicture, RiskSubCategory } from "@structs/risk";
import { combineLatest, of, Subscription } from "rxjs";
import { catchError, filter, first, map, switchMap } from "rxjs/operators";
import { PictureDetailComponent } from "../../picture-detail/picture-detail.component";
import { NavigationExtras, Router } from "@angular/router";
import { ScopeService } from "@services/scope.service";
import { RiskInformationsComponent } from "../risk-informations/risk-informations.component";
import { InvestmentsListPage } from "src/app/pages/investments-list/investments-list.page";
import { InvestmentsService } from "@services/investments.service";
import { SynchronizationService } from "@services";
import { SynchronizationStatus } from "@structs/synchronization";
import { SynthesisService } from "@services/synthesis.service";

@Component({
  selector: "app-risk-item",
  templateUrl: "./risk-item.component.html",
  styleUrls: ["./risk-item.component.scss"],
})
export class RiskItemComponent implements OnInit, OnDestroy {
  @Input() riskSubCategory: RiskSubCategory;
  @Input() risk: Risk;
  @Input() perimeter: Perimeter; // mono-perimeter
  @Input() site: Perimeter;
  @Input() budget: string = "";

  private subscriptions: Subscription[] = [];
  public inBrowser: boolean = this.browser.inBrowser();
  public loading: boolean = false;
  @ViewChild("browserFileUpload") browserFileUpload: ElementRef;
  @ViewChild("slidingItem") slidingItem: IonItemSliding;

  @Output() public addTaskEvent: EventEmitter<Risk> = new EventEmitter<Risk>();
  @Output() public deleteRiskEvent: EventEmitter<Risk> = new EventEmitter<Risk>();
  @Output() public changeCriticalityEvent: EventEmitter<Risk> = new EventEmitter<Risk>();

  constructor(
    private riskService: RiskService,
    private translate: TranslateService,
    private browser: BrowserService,
    private actionSheetCtrl: ActionSheetController,
    private picturesService: PicturesService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private router: Router,
    private scope: ScopeService,
    private investmentsService: InvestmentsService,
    private syncService: SynchronizationService,
    private synthesisService: SynthesisService
  ) {}

  ngOnInit() {}

  getRiskColor(criticality: number): string {
    let color: string;
    switch (criticality) {
      case 1:
        color = "risk-warning";
        break;
      case 2:
        color = "risk-important";
        break;
      case 3:
        color = "risk-major";
        break;
      case null:
        color = "";
        break;
      default:
        color = "risk-warning";
        break;
    }
    return color;
  }

  commentChanged(event) {
    const note = event.target.value;
    const data = {
      note: note,
    };
    this.riskService.updateRisk(this.risk.id, data).subscribe(savedRisk => {});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async addPicture() {
    if (!this.browser.inBrowser()) {
      const actionSheet = await this.actionSheetCtrl.create({
        header: this.translate.instant("Source type"),
        buttons: [
          {
            text: this.translate.instant("Camera"),
            handler: () => {
              this.picturesService
                .captureFromCamera()
                .pipe(map(filePath => this.addPictureToRisk({ filePath })))
                .subscribe();
            },
          },
          {
            text: this.translate.instant("Gallery"),
            handler: () => {
              this.picturesService
                .captureFromLibrary()
                .pipe(map(filePath => this.addPictureToRisk({ filePath })))
                .subscribe();
            },
          },
          {
            text: this.translate.instant("Cancel"),
            role: "cancel",
          },
        ],
      });
      await actionSheet.present();
    } else {
      // On Web application, fallback to upload file
      this.browserFileUpload.nativeElement.click();
    }
  }

  /**
   * Simulate taking picture in browser using HTML upload feature
   * @param $event: HTMLInputElement including target as files
   */
  addBrowserPicture($event: Event) {
    const files: FileList = (<HTMLInputElement>$event.target).files;
    if (files.length !== 0) {
      const file = files[0];
      this.addPictureToRisk({ browserFile: file });
    }
  }

  addPictureToRisk(file: { browserFile?: File; filePath?: string }) {
    if (this.slidingItem) {
      this.slidingItem.close();
    }

    this.loading = true;

    const { browserFile, filePath } = file,
      localId = getLocalId(),
      riskPicture = new Picture(0, null, null, 0, null, localId, "", filePath, browserFile);

    if (browserFile) {
      this.riskService.addBrowserPicture(this.risk.id, riskPicture).subscribe(
        pictureAdded => {
          this.riskService.riskListNeedsRefresh$.next(true);
          this.loading = false;
        },
        err => {
          this.riskService.riskListNeedsRefresh$.next(true);
          this.loading = false;
        }
      );
    } else {
      this.riskService.addDevicePicture(this.risk.id, riskPicture).subscribe(
        pictureAdded => {
          this.riskService.riskListNeedsRefresh$.next(true);
          this.loading = false;
        },
        err => {
          this.riskService.riskListNeedsRefresh$.next(true);
          this.loading = false;
        }
      );
    }
  }

  /**
   * Show the QuestionItemPicture
   * @param picture
   */
  async showPicture(picture: RiskPicture | Picture) {
    const pictureModal = await this.modalCtrl.create({
      component: PictureDetailComponent,
      componentProps: {
        picture: picture,
        riskId: this.risk.id,
      },
    });
    await pictureModal.present();
    await pictureModal.onWillDismiss().then(() => {
      this.riskService.riskListNeedsRefresh$.next(true);
    });
  }

  public async openRiskActionSheet() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant(this.riskSubCategory.name),
      buttons: [
        {
          text: this.translate.instant("Yes"),
          handler: () => {
            this.riskService
              .createPerimeterRisk(this.riskSubCategory.id, this.perimeter.id)
              .pipe(
                catchError(() => {
                  this.alertCtrl
                    .create({
                      message: this.translate.instant(
                        "Another user has already entered a risk of this type, please refresh your data to view it."
                      ),
                    })
                    .then(alert => alert.present());
                  return of(null);
                }),
                filter(risk => !!risk)
              )
              .subscribe(savedRisk => {
                this.riskService.riskListNeedsRefresh$.next(true);
              });
          },
        },
        {
          text: this.translate.instant("No"),
          handler: () => {
            this.deleteRiskEvent.emit(this.risk);
          },
        },
        {
          text: this.translate.instant("Cancel"),
          role: "cancel",
        },
      ],
    });
    await actionSheet.present();
  }

  public async showInfo() {
    const riskInfoModal = await this.modalCtrl.create({
      component: RiskInformationsComponent,
      componentProps: {
        riskSubCategory: this.riskSubCategory,
        color: this.getRiskColor(this.risk ? this.risk.criticality : this.riskSubCategory.criticality),
      },
      cssClass: "risk-informations-modal",
    });
    await riskInfoModal.present();
  }

  public async addInvestment(item: IonItemSliding) {
    item.close();
    this.subscriptions.push(
      combineLatest([this.scope.getCurrentMultiPerimeter(), this.riskService.perimeterRisks$.pipe(first())]).subscribe(
        async ([site, perimeterRisks]) => {
          let investmentModal = await this.modalCtrl.create({
            component: InvestmentsListPage,
            componentProps: {
              multiPerimeterId: site.id,
              showAddButton: true,
              selectMode: true,
              risksList: perimeterRisks,
              monoPerimeterId: this.perimeter.id,
              // I choosed to hide the filters, so the user won't be able to
              // attach an investment from another building
              showFilters: false,
            },
            showBackdrop: true,
          });
          await investmentModal.present();
          const { data } = await investmentModal.onDidDismiss();
          if (data.investment) {
            this.loading = true;
            this.riskService.attachInvestment(this.risk.id, data.investment.id).subscribe(
              updatedRisk => {
                this.loading = false;
                this.riskService.riskListNeedsRefresh$.next(true);
              },
              err => {
                this.loading = false;
              }
            );
          }
          if (data.waitForNewInvestment) {
            // The user wants to create a new investment. Wait for the wizard
            // to finish. Then the newInvestmentCreated$ observable will allow us to
            // attach the new investment.
            this.waitForNewInvestment();
          }
        }
      )
    );
  }

  public addTask(item: IonItemSliding) {
    item.close();
    this.addTaskEvent.emit(this.risk);
  }

  public openTask() {
    const navExtras: NavigationExtras = {
      state: {
        risk: this.risk,
      },
    };
    this.router.navigate(["tasks/task-detail", this.risk.task_ids[0]], navExtras);
  }

  public changeCriticality(item: IonItemSliding) {
    item.close();
    this.changeCriticalityEvent.emit(this.risk);
  }

  public openInvestment() {
    this.router.navigate(["perimeters", this.site.id, "investment-detail", this.risk.investment_ids[0]]);
  }

  public waitForNewInvestment() {
    this.riskService.setPendingRiskId(this.risk.id);
    this.subscriptions.push(
      combineLatest([this.investmentsService.newInvestmentCreated$, this.syncService.watchSynchronizationState()])
        .pipe(
          filter(
            ([newInvestment, synchronizationState]) =>
              this.riskService.getPendingRiskId() === this.risk.id &&
              synchronizationState.status === SynchronizationStatus.DONE
          ),
          switchMap(([newInvestment, synchronizationState]) => {
            this.loading = true;
            return this.investmentsService.refreshInvestmentId(newInvestment);
          }),
          switchMap(newInvestmentData => {
            if (newInvestmentData.investmentId) {
              return this.riskService.attachInvestment(this.risk.id, newInvestmentData.investmentId);
            } else {
              return of(null);
            }
          }),
          switchMap(() => this.synthesisService.getAuditSynthesis(this.site, new Date().getFullYear()))
        )
        .subscribe(
          () => {
            this.loading = false;
            this.riskService.setPendingRiskId(null);
            this.riskService.riskListNeedsRefresh$.next(true);
          },
          err => {
            this.loading = false;
          }
        )
    );
  }
}
