import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import { FileTransfer, FileTransferError, FileUploadOptions } from "@awesome-cordova-plugins/file-transfer/ngx";
import * as IonicFile from "@ionic-native/file";
import { from, Observable } from "rxjs";
import { catchError, filter, first, map, switchMap, take, tap } from "rxjs/operators";

import { AlertController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { TranslateService } from "@ngx-translate/core";
import { makeAssetPicture, makeInvestmentPicture, makePerimeterPicture, makePicture, Picture } from "@structs";
import { Change, ChangeAction } from "@structs/synchronization";
import { Environment } from "../app.environment";
import { ImagesService } from "../src/app/services/images.service";
import { AuthService } from "./auth.service";

@Injectable()
export class PicturesService {
  private options: CameraOptions = {
    destinationType: this.camera.DestinationType.FILE_URI,
    targetWidth: 2048,
    targetHeight: 2048,
    correctOrientation: true,
  };
  /**
   * Keep browser File upload references, because change serialization breaks them.
   */
  private browserFiles: { [localId: string]: File } = {};

  constructor(
    private authService: AuthService,
    private camera: Camera,
    // private file: IonicFile,
    private httpClient: HttpClient,
    private transfer: FileTransfer,
    private storage: Storage,
    private imageService: ImagesService,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {}

  public captureFromCamera(): Observable<string> {
    return this.capture(this.camera.PictureSourceType.CAMERA);
  }

  public captureFromLibrary(): Observable<string> {
    return this.capture(this.camera.PictureSourceType.PHOTOLIBRARY);
  }

  public upload(change: Change): Observable<Picture> {
    const { browserFile }: Picture = change.data;

    if (browserFile) {
      return this.browserUpload(change);
    } else {
      return this.nativeUpload(change);
    }
  }

  public getBrowserFile(localId: string): File {
    return this.browserFiles[localId];
  }

  public getBrowserFile$(localId: string) {
    const toFind = `picture:${localId}`;
    return from(this.storage.get(toFind)).pipe(take(1)) as Observable<File>;
  }

  /**
   * @deprecated use setBrowserFile$
   * @param browserFile
   * @param localId
   */
  public setBrowserFile(browserFile: File, localId: string): void {
    this.browserFiles[localId] = browserFile;
  }

  public setBrowserFile$(browserFile: File, localId: string): Observable<string> {
    this.browserFiles[localId] = browserFile;
    const toFind = `picture:${localId}`;
    const picture = new Picture(0, "", "", 0, "", "", "", undefined, browserFile);
    const resizedPicture = this.imageService.resizePicture(picture, { width: 720, height: 1080 }, "blob");
    return from(this.storage.set(toFind, resizedPicture)).pipe(
      take(1),
      map(() => localId)
    );
  }

  public nativeUpload(change: Change): Observable<Picture> {
    const { localPath, localId }: Picture = change.data;

    let asset: number = null,
      investment: number = null,
      perimeter: number = null,
      question_item: number = null;

    if (change.type === ChangeAction.addAssetPictureAction) {
      asset = change.data.asset;
    } else if (change.type === ChangeAction.addPerimeterPictureAction) {
      perimeter = change.data.perimeter;
    } else if (change.type === ChangeAction.addAuditQuestionPictureAction) {
      question_item = change.data.questionItem;
      asset = change.data.asset;
    } else {
      investment = change.data.investment;
    }
    const fileName: string = localPath.substring(localPath.lastIndexOf("/") + 1);

    return from(this.authService.getAuthorizationString()).pipe(
      switchMap(token => {
        const fileTransfer = this.transfer.create();
        const options: FileUploadOptions = {
          fileKey: "picture",
          fileName,
          params: {
            asset,
            investment,
            perimeter,
            question_item,
            local_id: localId,
          },
          httpMethod: change.method,
          headers: {
            Authorization: token,
          },
          chunkedMode: false,
        };

        return from(fileTransfer.upload(localPath, `${Environment.getBackendHost()}${change.url}`, options)).pipe(
          map(result => {
            if (asset) {
              return makeAssetPicture(JSON.parse(result.response));
            }

            if (investment) {
              return makeInvestmentPicture(JSON.parse(result.response));
            }

            if (perimeter) {
              return makePerimeterPicture(JSON.parse(result.response));
            }

            return makePicture(JSON.parse(result.response));
          })
        );
      })
    );
  }

  public browserUpload(change: Change): Observable<Picture> {
    const { localId }: Picture = change.data;

    let asset: number = null,
      investment: number = null,
      perimeter: number = null,
      question_item: number = null;

    if (change.type === ChangeAction.addAssetPictureAction) {
      asset = change.data.asset;
    } else if (change.type === ChangeAction.addPerimeterPictureAction) {
      perimeter = change.data.perimeter;
    } else if (change.type === ChangeAction.addAuditQuestionPictureAction) {
      question_item = change.data.questionItem;
      asset = change.data.asset;
    } else {
      investment = change.data.investment;
    }
    const browserFile = this.getBrowserFile(localId);

    // We lost the browser file reference, let's try to recover it
    if (!browserFile) {
      console.warn("in-memory picture is cleared. try to use storage");
      return this.getBrowserFile$(localId).pipe(
        first(),
        map(browserFile => {
          if (!!browserFile) {
            console.log("picture found in storage. sending...");
            return browserFile;
          }
          console.error(`storage picture ${localId} is not found. picture is missing! for change ${change}`);
          return null;
        }),
        filter(bf => !!bf),
        switchMap(browserFile =>
          this._realBrowserUpload({
            browserFile,
            asset,
            investment,
            perimeter,
            question_item,
            localId,
            change,
          })
        )
      );
    }

    return this._realBrowserUpload({ browserFile, asset, investment, perimeter, question_item, localId, change });
  }

  private _realBrowserUpload(obj: {
    browserFile: File;
    asset: number;
    investment: number;
    perimeter: number;
    question_item: number;
    localId: string;
    change: Change;
  }) {
    const { browserFile, asset, investment, perimeter, question_item, localId, change } = obj;
    return from(this.authService.getAuthorizationString()).pipe(
      switchMap(token => {
        const formData = new FormData();
        formData.append("picture", browserFile, browserFile.name);
        if (asset) {
          formData.append("asset", asset.toString());
        } else if (investment) {
          formData.append("investment", investment.toString());
        } else if (perimeter) {
          formData.append("perimeter", perimeter.toString());
        }
        if (question_item) {
          formData.append("question_item", question_item.toString());
        }
        formData.append("local_id", localId);

        return this.httpClient
          .post<Picture>(`${Environment.getBackendHost()}${change.url}`, formData, {
            headers: {
              Authorization: token,
            },
          })
          .pipe(
            map(result => {
              if (asset) {
                return makeAssetPicture(result);
              }

              if (investment) {
                return makeInvestmentPicture(result);
              }

              if (perimeter) {
                return makePerimeterPicture(result);
              }

              return makePicture(result);
            }),
            tap(() => {
              console.log("delete pendingPicture that synced");
              this.deletePicturePending$(localId).subscribe();
            })
          );
      })
    );
  }

  public isNetworkError(error: FileTransferError): boolean {
    return error.code === this.transfer.FileTransferErrorCode.CONNECTION_ERR;
  }

  private capture(source: number): Observable<string> {
    return from(
      this.camera.getPicture({
        ...this.options,
        sourceType: source,
      })
      // By default, the picture is stored in cache. Copy it into persistent storage.
    )
      .pipe(
        catchError(async error => {
          await this.alertCtrl.create({
            message: this.translate.instant(
              "Missing permissions for the application please check the settings or contact support."
            ),
            htmlAttributes: {
              "aria-label": "alert dialog",
            },
          });
          throw new Error(`Failed to capture picture: ${error}`);
        }),
        switchMap((cachedPath: string) => {
          // Resolve the path to get a truly native one
          return from(IonicFile.File.resolveLocalFilesystemUrl(cachedPath));
        })
      )
      .pipe(
        switchMap(fileEntry => {
          const nativePath = fileEntry.nativeURL;
          const directoryPath = nativePath.substring(0, nativePath.lastIndexOf("/") + 1);
          let targetDirectory: string;

          if (IonicFile.File.externalDataDirectory) {
            // Running on Android
            targetDirectory = IonicFile.File.externalDataDirectory;
          } else if (IonicFile.File.documentsDirectory) {
            // Running on iOS
            targetDirectory = IonicFile.File.documentsDirectory;
          } else {
            throw new Error("Unsupported platform");
          }

          const newName = `${new Date().getTime()}.jpg`;
          return from(IonicFile.File.moveFile(directoryPath, fileEntry.name, targetDirectory, newName)).pipe(
            map(() => `${targetDirectory}${newName}`)
          );
        })
      );
  }

  getAllPictureKeysPendingToUpload() {
    return from(this.storage.keys()).pipe(
      map(keys => keys.filter(key => key.startsWith("picture:"))),
      tap(k => console.log(k))
    );
  }

  getPicturePendingToUpload(localId: string) {
    const toFind = localId.startsWith("picture") ? localId : `picture:${localId}`;
    return this.getAllPictureKeysPendingToUpload().pipe(
      map(keys => keys.find(key => key === toFind)),
      // in case of null
      filter(key => !!key),
      switchMap(key => <Promise<File>>this.storage.get(key))
    );
  }

  private deletePicturePending$(localId: string) {
    const toFind = `picture:${localId}`;
    return from(this.storage.remove(toFind));
  }
}
